import { Box, VStack } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { CustomConnectkitButton } from "./CustomConnectkitButton";

type ConnectButtonProps = {
  walletSvg: string | undefined;
  ensOrAddress: string | undefined;
};

export default function ConnectButton(props: ConnectButtonProps) {
  // 4. Use modal hook
  const { address, isConnected } = useAccount();

  function getWalletString() {
    if (!props.ensOrAddress?.startsWith("0x")) {
      return props.ensOrAddress;
    } else if (props.ensOrAddress !== undefined) {
      return `${props.ensOrAddress?.slice(0, 5)}..${props.ensOrAddress?.slice(
        -3
      )}`;
    } else if (address !== undefined) {
      return `${address.slice(0, 5)}..${address.slice(-3)}`;
    }
  }

  return (
    <Box>
      {!isConnected && <CustomConnectkitButton />}
      {isConnected && props.walletSvg && (
        <VStack>
          <Box textAlign="center">{getWalletString()}</Box>
          <div
            dangerouslySetInnerHTML={{ __html: props.walletSvg }}
            style={{
              borderColor: "hsl(25,0%,10%)",
              borderWidth: "1px",
              padding: "0px",
              width: "70px",
              height: "70px",
            }}
          />
        </VStack>
      )}
      {
        //'<button onClick={() => open({ view: "Networks" })}>'}
        //"  Open Network Modal"}
        //</Box>"</button>"
      }
    </Box>
  );
}
