import { useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";
import { SVG, Element } from "@svgdotjs/svg.js";

export default function useLine(mobile: boolean, forceUpdate: boolean) {
  let [displayedSvg, setDisplayedSvg] = useState<string>("");
  let [previousSvg, setPreviousSvg] = useState<string>("");
  let [previousSlot, setPreviousSlot] = useState<bigint>();
  let [nextSvg, setNextSvg] = useState<string>("");
  let [nextSlot, setNextSlot] = useState<bigint>();
  let [line, setLine] = useState<string>("");

  // 4. Use modal hook
  const { data, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "renderCurrent",
    args: [mobile],
    watch: false,
  });

  function fadeIn(previousSvg: Element, newJoiners: Element[]) {
    let copyNewJoiners: Element[] = [];
    for (let i = 0; i < newJoiners.length; i++) {
      copyNewJoiners.push(newJoiners[i].clone());
    }

    for (let i = 0; i < copyNewJoiners.length; i++) {
      const fadeIn = SVG(
        `<animate attributeName="opacity" from="0" to="1" dur="1s" repeatCount="1" fill="freeze" />`
      );
      copyNewJoiners[i].add(fadeIn);
    }

    const previousContainer = previousSvg.find("svg > g")[0];
    copyNewJoiners.forEach(function (element, members) {
      previousContainer.add(element);
    });
  }

  useEffect(() => {
    const interval = setInterval(refetch, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    if (forceUpdate) {
      refetch();
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (
      nextSvg &&
      previousSvg &&
      nextSlot !== undefined &&
      previousSlot !== undefined
    ) {
      const previousSvgElement = SVG(previousSvg);
      const nextSvgElement = SVG(nextSvg);
      const previousPath = previousSvgElement.find("#l")[0];
      const nextPath = nextSvgElement.find("#l")[0];
      if (previousPath && nextPath) {
        let animation = `<animate begin="0" dur="1s" repeatCount="1" attributeName="d" fill="freeze" from="${previousPath.attr(
          "d"
        )}"
    to="${nextPath.attr("d")}"  />`;
        previousPath.add(SVG(animation));
      } else if (!previousPath && !nextPath) {
        const rectsPrevious = previousSvgElement.find("g.s");
        const rectsNext = nextSvgElement.find("g.s");

        const slotDiff = Number(nextSlot - previousSlot);
        const newJoinersLength = Math.max(
          0,
          rectsNext.length + slotDiff - rectsPrevious.length
        );

        // there are slotdiff less blocks in the new line
        // newjoiners are next line length + slot diff - previous line length
        if (slotDiff !== 0) {
          // there are people out
          // Animate existing squares in and fade out first squares
          const blocksAtATime = Math.floor(Math.random() * 7) + 1;
          const totalTime = Math.floor(Math.random() * 7) + 5;
          let animationTime = (totalTime / rectsNext.length) * blocksAtATime;
          console.log(animationTime);
          animationTime = Math.min(0.5, animationTime);

          for (let i = 0; i < rectsPrevious.length; i++) {
            //console.log(rectsPrevious[i]);
            if (i < slotDiff) {
              rectsPrevious[i].add(
                SVG(
                  `<animate attributeName="opacity" begin="0" from="1" to="0" dur="1s" repeatCount="1" fill="freeze" />`
                )
              );
            } else {
              const pathPrevious = rectsPrevious[i].find("path")[0].attr("d");
              const pathNext = rectsNext[i - slotDiff]
                .find("path")[0]
                .attr("d");

              const detailsPrevious = rectsPrevious[i].find("use");
              const detailsNext = rectsNext[i - slotDiff].find("use");
              for (
                let j = 0;
                j < Math.min(detailsNext.length, detailsPrevious.length);
                j++
              ) {
                const prevX = detailsPrevious[j].attr("x");
                const prevY = detailsPrevious[j].attr("y");
                const nextX = detailsNext[j].attr("x");
                const nextY = detailsNext[j].attr("y");

                let animationX = `<animate begin="${
                  animationTime * Math.floor(i / blocksAtATime)
                }" dur="${animationTime}s" repeatCount="1" attributeName="x" fill="freeze" from="${prevX}"
          to="${nextX}"  />`;

                let animationY = `<animate begin="${
                  animationTime * Math.floor(i / blocksAtATime)
                }" dur="${animationTime}s" repeatCount="1" attributeName="y" fill="freeze" from="${prevY}"
          to="${nextY}"  />`;

                detailsPrevious[j].add(SVG(animationX));
                detailsPrevious[j].add(SVG(animationY));
              }

              let animation = `<animate begin="${
                animationTime * Math.floor(i / blocksAtATime)
              }" dur="${animationTime}s" repeatCount="1" attributeName="d" fill="freeze" from="${pathPrevious}"
        to="${pathNext}"  />`;
              rectsPrevious[i].find("path")[0].add(SVG(animation));
            }
          }
        }

        const newJoiners = rectsNext.slice(
          rectsNext.length - newJoinersLength,
          rectsNext.length
        );
        if (newJoiners.length > 0) {
          fadeIn(previousSvgElement, newJoiners);
        }
      }

      const viewBoxAnimation = `<animate attributeName="viewBox" to="${nextSvgElement.attr(
        "viewBox"
      )}" dur="0.5s" fill="freeze"/>`;
      previousSvgElement.add(SVG(viewBoxAnimation));
      setDisplayedSvg(previousSvgElement.svg());
      setPreviousSvg(nextSvgElement.svg());
      setPreviousSlot(nextSlot);
    } else {
      setDisplayedSvg(nextSvg);
      setPreviousSvg(nextSvg);
      setPreviousSlot(nextSlot);
    }
  }, [nextSvg, nextSlot]);

  useEffect(() => {
    if (displayedSvg) {
      const b64svg = Buffer.from(displayedSvg).toString("base64");
      setLine(`data:image/svg+xml;base64,${b64svg}`);
    }
  }, [displayedSvg]);

  useEffect(() => {
    if (data) {
      const [svgData, currentSlot, count] = data as [string, bigint, bigint];
      if (svgData !== nextSvg) {
        setNextSvg(svgData);
        setNextSlot(currentSlot);
      }
    }
  }, [data]);

  return line;
}
