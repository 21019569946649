import { useState, useEffect } from "react";
export function useCountdown(initialSecondsLeft: number) {
  const [secondsLeft, setSecondsLeft] = useState(initialSecondsLeft);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (secondsLeft > 0) {
      const setProgressLevel = setInterval(() => {
        setProgress(progress + 100 / secondsLeft);
        setSecondsLeft((oldsecondsLeft) => oldsecondsLeft - 1);
      }, 1000);
      return () => clearInterval(setProgressLevel);
    }
  }, [progress, secondsLeft]);

  useEffect(() => {
    setSecondsLeft(initialSecondsLeft);
  }, [initialSecondsLeft]);

  const min = Math.floor((secondsLeft / 60) % 60);
  const sec = Math.floor(secondsLeft % 60);
  const hour = Math.floor(secondsLeft / 3600);
  const minutesDisplay = min < 10 ? "0" + min : min;
  const secondsDisplay = sec < 10 ? "0" + sec : sec;
  return [hour, minutesDisplay, secondsDisplay];
}
