import {
  Box,
  Flex,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
} from "@chakra-ui/react";
import Line from "./Line";
import { useEffect, useState } from "react";
import "./Main.css";
import "./Content.css";
import EnterButton from "./EnterButton";
import InFrontText from "./InFrontText";
import MintButton from "./MintButton";
import CountdownText from "./Countdown";
import ConnectButton from "./ConnectButton";
import { EtherscanIcon, OpenseaIcon } from "./Icons";

function Content(props: {
  size: number;
  auctionRef: React.RefObject<HTMLHeadingElement>;
  line: string;
  setUpdate: (doUpdate: boolean) => void;
  update: boolean;
  firstPosition: bigint | undefined;
  positions: bigint[];
  mintBegin: bigint;
  queueBegin: bigint;
  canMint: boolean;
  currentBlock: bigint | undefined;
  walletSvg: string | undefined;
  ensOrAddress: string | undefined;
  count: number;
  lineLength: bigint | undefined;
  currentTokenId: bigint | undefined;
}) {
  // Determine if the viewport width allows for side content
  const [boxSize, setBoxSize] = useState<number>(100);

  const options = {
    month: "long" as const,
    day: "numeric" as const,
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: true, // Use 24-hour format, change to true for AM/PM format
  };

  const mintingDate = new Date(process.env.REACT_APP_MINTING_DATE!);
  const showBoxes =
    props.currentBlock && props.queueBegin >= props.currentBlock + BigInt(4);

  const executeScroll = () =>
    //aboutRef.current?.scrollBy({
    window.scrollBy({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });

  const executeScrollToRules = () => {
    props.auctionRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    const size = props.size / 4.5;
    if (size < 180) {
      setBoxSize(180);
    } else {
      setBoxSize(size);
    }
  }, [props.size]);

  return (
    <Flex width="100%" height="100vh" align="center" justify="center">
      {/* Left box taking up remaining space */}
      <Box
        flex="1"
        height="100vh"
        textAlign="center"
        paddingTop={5}
        paddingBottom={5}
      >
        <Flex direction="column" height="100vh">
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">The Queue</Text>
            <InFrontText
              firstPosition={props.firstPosition}
              canMint={props.canMint}
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
              count={props.count}
              update={props.update}
            />
            <EnterButton
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
              update={() => props.setUpdate(true)}
              count={props.count}
              queueBegin={props.queueBegin}
            />
            <CountdownText
              mintBegin={props.queueBegin}
              currentBlock={props.currentBlock}
            />
            {/*
              <LineLengthText
                lineLength={lineLength === undefined ? BigInt(0) : lineLength}
                mintBegin={mintBegin}
                currentBlock={currentBlock}
              ></LineLengthText>
  */}
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">Mint</Text>
            Will start after the queueing on{" "}
            {/**<MintButton
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
              positions={props.positions}
              canMint={props.canMint}
              lineLength={props.lineLength}
              count={props.count}
/>*/}
            {mintingDate.toLocaleString("en-US", options)}
            {/**
            <CountdownText
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
            />
            */}
            {props.mintBegin !== undefined &&
              props.currentBlock !== undefined &&
              props.currentBlock >= props.mintBegin && (
                <Text className="blink" variant="home">
                  MINTING
                </Text>
              )}
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">You</Text>
            <ConnectButton
              walletSvg={props.walletSvg}
              ensOrAddress={props.ensOrAddress}
            />
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">Length</Text>
            {props.mintBegin === undefined ||
              props.currentBlock === undefined ||
              (props.mintBegin > props.currentBlock && (
                <Text variant="home">{props.lineLength?.toString()}</Text>
              ))}
            {props.mintBegin !== undefined &&
              props.currentBlock !== undefined &&
              props.mintBegin <= props.currentBlock && (
                <Text variant="home">
                  {props.currentTokenId?.toString()}/
                  {props.lineLength?.toString()}
                </Text>
              )}
          </Box>

          <Spacer />
        </Flex>
      </Box>

      {/* Central box with fixed props.size */}
      <Box position="relative" width={props.size} height={props.size}>
        <Line line={props.line} />
        <Box
          width="130px"
          height="130px"
          top="30%"
          left="20%"
          className={`home-box first-box show-${showBoxes}`}
        >
          Queue Up.
        </Box>
        <Box
          width="130px"
          height="130px"
          top="30%"
          left="42%"
          className={`home-box show-${showBoxes}`}
        >
          Wait.
        </Box>
        <Box
          width="130px"
          height="130px"
          top="30%"
          left="64%"
          className={`home-box show-${showBoxes}`}
        >
          Mint.
        </Box>
        <Box
          width="150px"
          height="150px"
          top="50%"
          left="30%"
          className={`home-box colored-red show-${showBoxes}`}
        >
          The
        </Box>
        <Box
          width="150px"
          height="150px"
          top="50%"
          left="52%"
          className={`home-box colored-green show-${showBoxes}`}
        >
          Queue.
        </Box>
      </Box>

      {/* Right box taking up remaining space */}
      <Box
        flex="1"
        height="100vh"
        textAlign="center"
        paddingTop={5}
        paddingBottom={5}
      >
        <Flex direction="column" height="100vh">
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">NFT</Text>
            <Text variant="home">
              A fragment of the line.
              <br />
              In space, and in time.
              <br />
              100% On-chain.
            </Text>
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">Rules</Text>
            <Text variant="home">
              Queue up.
              <br /> Mint when it's your turn.
            </Text>
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Link textDecoration="underline" onClick={executeScrollToRules}>
              <Text variant="homeHeading">Price</Text>
            </Link>
            <Text variant="home">
              Increases with time. After half the queue, it goes down. Starts
              and Ends at 0.05ETH.
            </Text>
          </Box>
          <Spacer />
          <Box className="box" width={boxSize} height={boxSize}>
            <Text variant="homeHeading">About</Text>
            <HStack>
              <Link
                isExternal
                href="https://etherscan.io/address/0x999c11bbFc1e6C60EC132E1d2D5319c9D67E9999"
              >
                <IconButton
                  aria-label="Etherscan"
                  icon={<EtherscanIcon />}
                ></IconButton>
              </Link>
              <Link
                isExternal
                href="https://opensea.io/collection/the-queue-by-miragenesi"
              >
                <IconButton
                  style={{
                    paddingTop: "4px",
                  }}
                  aria-label="Opensea"
                  icon={<OpenseaIcon />}
                ></IconButton>
              </Link>
            </HStack>
            <Link
              marginTop={1}
              onClick={executeScroll}
              fontWeight="bold"
              fontSize="xl"
            >
              ↓
            </Link>
          </Box>
          <Spacer />
        </Flex>
      </Box>
    </Flex>
  );
}

export default Content;
