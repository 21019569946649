import { Button } from "@chakra-ui/react";
import { ConnectKitButton } from "connectkit";

export const CustomConnectkitButton = () => {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName }) => {
        return (
          <Button onClick={show}>
            {isConnected ? ensName ?? truncatedAddress : "Connect"}
          </Button>
        );
      }}
    </ConnectKitButton.Custom>
  );
};
