import { useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";

export default function useLineLength() {
  let [lineLength, setLineLength] = useState<bigint>();

  const { data, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "queueLength",
    watch: false,
  });

  useEffect(() => {
    const lineLengthData = data as bigint;
    if (lineLengthData !== lineLength || lineLength === undefined) {
      setLineLength(lineLengthData);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(refetch, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  return lineLength;
}
