import { Box, Button, useToast } from "@chakra-ui/react";
import { useAccount, useContractWrite, useWaitForTransaction } from "wagmi";
import { ABI_NFT } from "./contract";
import { useModal } from "connectkit";
import { useEffect } from "react";

export default function EnterButton(props: {
  update: () => void;
  mintBegin: bigint;
  queueBegin: bigint;
  currentBlock: bigint | undefined;
  count: number;
}) {
  // 4. Use modal hook

  const toast = useToast();
  const { address, isConnected } = useAccount();
  const connectModal = useModal();

  const { data, write, isError } = useContractWrite({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "enter",
    account: address,
  });

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description: "There was an error while entering.",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
    }
  }, [isError]);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 1,
    onSuccess: () => {
      console.log("success");
      props.update();
      toast({
        title: "Success",
        description: "You are in line.",
        isClosable: true,
        status: "success",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "green",
        },
      });
    },
    onError: (error) => {
      console.log(error);
      console.log("ERROR");
      toast({
        title: "Error",
        description: "There was an error while entering.",
        isClosable: true,
        status: "error",
        containerStyle: {
          backgroundColor: "black",
          borderColor: "red",
        },
      });
    },
  });

  //const { data, isLoading, isSuccess, write } = useContractWrite(config);

  return (
    <Box marginTop={2}>
      {props.mintBegin !== undefined &&
        props.queueBegin !== undefined &&
        props.currentBlock !== undefined &&
        props.queueBegin < props.currentBlock &&
        props.mintBegin >= props.currentBlock && (
          <Button
            isDisabled={waitForTransaction.isLoading}
            isLoading={waitForTransaction.isLoading}
            onClick={() =>
              !isConnected ? connectModal.setOpen(true) : write?.()
            }
          >
            QUEUE
          </Button>
        )}
      {props.mintBegin !== undefined &&
        props.queueBegin !== undefined &&
        props.currentBlock !== undefined &&
        (props.queueBegin >= props.currentBlock ||
          props.mintBegin <= props.currentBlock) && (
          <Box>
            <Button isDisabled={true}>QUEUE</Button>
          </Box>
        )}
      {props.count > 0 && (
        <Box marginTop={1} className="highlight">{`eligible for ${
          props.count * 2
        } nfts`}</Box>
      )}
    </Box>
  );
}
