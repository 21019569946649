import { useEffect, useState } from "react";

export default function useLocalSVG(name: string) {
  const [svg, setSvg] = useState<string>("");

  useEffect(() => {
    fetch(name)
      .then((response) => response.text())
      .then((data) => {
        setSvg(data);
      });
  }, [name]);

  return svg;
}
