import {
  Box,
  Center,
  HStack,
  Heading,
  IconButton,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import "./About.css";
import { ForwardedRef } from "react";
import useLocalSVG from "./useLocalSVG";

type AboutProps = {
  aboutRef: ForwardedRef<HTMLHeadingElement>;
  rulesRef: ForwardedRef<HTMLHeadingElement>;
  mobile: boolean;
};

export default function About(props: AboutProps) {
  const sample1 = useLocalSVG(`samples/1${props.mobile ? "m" : ""}.svg`);
  const sample2 = useLocalSVG(`samples/2${props.mobile ? "m" : ""}.svg`);
  const sample3 = useLocalSVG(`samples/3${props.mobile ? "m" : ""}.svg`);
  const sample4 = useLocalSVG(`samples/4${props.mobile ? "m" : ""}.svg`);

  const openingDate = new Date(process.env.REACT_APP_OPENING_DATE!);

  const options = {
    month: "long" as const,
    day: "numeric" as const,
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: true, // Use 24-hour format, change to true for AM/PM format
  };

  return (
    <Box
      className="blur"
      margin="0 auto"
      width={props.mobile ? "100%" : "80%"}
      textAlign="center"
    >
      <Heading
        ref={props.aboutRef}
        as="h1"
        size="xl"
        marginTop={props.mobile ? 0 : 10}
        marginBottom={10}
      >
        About
      </Heading>
      <SimpleGrid
        minChildWidth={props.mobile ? "350px" : "420px"}
        spacing="40px"
      >
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading marginBottom={2} as="h1" size="lg">
            A Collective Superstructure
          </Heading>
          <Text fontSize="md">
            <i>The Queue</i> is a collectively generated NFT. Queueing to
            acquire it is what creates it. <br />
            <br />
            Each user is represented by a <i>block</i>, whose aesthetics is
            generated from the wallet. The more people enter the queue, the more
            the queue grows, in length, an in complexity.
            <br />
            <br />
            Each NFT is a fragment of the queue, <i>ON</i> some <i>block</i>{" "}
            (space) and <strong>AT</strong> some <strong>block</strong> (time).
          </Text>
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading marginBottom={2} as="h1" size="lg">
            The NFT
          </Heading>
          <Text fontSize="md">
            Each token is a <i>photo</i> of a random part of the queue, at
            varying height.
            <br />
            <br />
            Each token also <i>contains</i> the queue itself, at the block
            represented by its ID. Token #42 will contain the queue at the state
            after 42 users have been served.
            <br />
            <br />
            You can explore your token and change its face.
          </Text>
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <div
            dangerouslySetInnerHTML={{ __html: sample1 }}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading marginBottom={2} as="h1" size="lg">
            The Art of Waiting
          </Heading>
          <Text fontSize="md">
            <i>The queue</i> challenges the instant gratification norm of the
            digital space and replaces it with a meditative, communal journey.
            <br />
            <br />
            When the minting begins, a new user is served every time Ethereum
            produces a new <strong>block</strong>. One <strong>block</strong> in
            for one <i>block</i> out.
          </Text>
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <div
            dangerouslySetInnerHTML={{ __html: sample2 }}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading marginBottom={2} as="h1" size="lg">
            The Subject
          </Heading>
          <Text fontSize="md" marginBottom={1}>
            Each token focuses on a user in line, the <strong>subject</strong>.
            <br />
            <br />
            <Center>
              <Image src="subject.png" />
            </Center>
            <br />
            All subjects will have their ENS or address stamped on the token
            traits.
          </Text>
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <div
            dangerouslySetInnerHTML={{ __html: sample3 }}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading ref={props.rulesRef} marginBottom={2} as="h1" size="lg">
            Berg Auction
          </Heading>
          <Text fontSize="md">
            Price will follow a <strong>Berg Auction</strong>:
            <br />
            It will go up by 0.001ETH at every block until half the queue, then
            it will go down again until the end.
            <br />
            Initial and Final price will be 0.05ETH.
            <br />
            <br />
            Tokens minted in the same transaction will all have the same price.
          </Text>
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading marginBottom={2} as="h1" size="lg">
            Rules
          </Heading>
          <Text fontSize="md">
            Enter the queue during the queueing (3 days).
            <br /> After that, the queue closes, the supply is set to the{" "}
            <strong>length of the queue</strong> and the minting starts.
            <br />
            <br />
            Mint at any time from your turn until the end of the queue. Mint up
            to 2 tokens per each time you entered the line.
            <br />
            <br />
            The tokens will become tradeable only after the end of the minting.
          </Text>
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <div
            dangerouslySetInnerHTML={{ __html: sample4 }}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading marginBottom={2} as="h1" size="lg">
            In Memory Of
          </Heading>
          <Image alt="Vera Molnár" width="80%" src="vera_square.png" />
        </Box>
        <Box className={`about-box ${props.mobile ? "about-box-mobile" : ""}`}>
          <Heading marginBottom={2} as="h1" size="lg">
            The Author
          </Heading>
          <Text fontSize="md">
            <Link
              textDecoration="underline"
              target="blank"
              href="https://twitter.com/miragenesi"
            >
              Miragenesi,
            </Link>
            <br />
            feat.{" "}
            <i>
              <Link
                isExternal
                textDecoration="underline"
                href="https://fingerprintsdao.xyz/"
              >
                Fingerprints DAO
              </Link>
            </i>
            <br />
            <br />I love Synth Music and On-Chain NFT Art.
            <br />
            <br />I hate queues.
            <Center>
              <HStack
                width="80%"
                marginTop="20px"
                marginBottom="20px"
                alignItems="center"
                justifyContent="center"
              >
                <Link isExternal href="http://discord.miragenesi.art">
                  <IconButton
                    aria-label="Discord"
                    icon={
                      <Image
                        border="none"
                        backgroundColor="white"
                        width="34px"
                        src="discord.svg"
                      />
                    }
                  ></IconButton>
                </Link>
                <Link isExternal href="https://linktr.ee/miragenesi">
                  <IconButton
                    aria-label="Linktree"
                    icon={
                      <Image
                        border="none"
                        backgroundColor="white"
                        width="34px"
                        src="linktree.png"
                      />
                    }
                  ></IconButton>
                </Link>
                <Link isExternal href="https://twitter.com/miragenesi">
                  <IconButton
                    aria-label="X"
                    icon={
                      <Image
                        backgroundColor="white"
                        width="34px"
                        src="x.jpeg"
                      />
                    }
                  ></IconButton>
                </Link>
                <Link isExternal href="https://medium.com/@miragenesi">
                  <IconButton
                    aria-label="Medium"
                    icon={
                      <Image
                        backgroundColor="white"
                        width="34px"
                        src="medium.svg"
                      />
                    }
                  ></IconButton>
                </Link>
                <Link isExternal href="https://music.miragenesi.art">
                  <IconButton
                    aria-label="Bandcamp"
                    icon={
                      <Image
                        backgroundColor="white"
                        width="34px"
                        src="bandcamp.png"
                      />
                    }
                  ></IconButton>
                </Link>
              </HStack>
            </Center>
          </Text>
        </Box>
      </SimpleGrid>
      <Box
        width={props.mobile ? "100%" : "80%"}
        marginTop={10}
        paddingTop={10}
        className={`faq-box`}
      >
        <Heading as="h1" size="lg" marginBottom={10}>
          Frequently Asked Questions
        </Heading>
        <Text fontSize="lg" fontWeight="bold">
          How do I line up for the queue?
        </Text>
        <Text fontSize="md">
          You can line up when The Queue opens by connecting your wallet and
          paying a gas fee to hold your place in the queue.{" "}
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          How long will the queue be open?{" "}
        </Text>
        <Text fontSize="md">
          The Queue will open on {openingDate.toLocaleString("en-US", options)}{" "}
          and remain open for 3 days (72 hours)
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          How many pieces can I line up for in the queue?
        </Text>
        <Text fontSize="md">
          Each time you line up, you get access to up to 2 pieces. So, if for
          instance you want to mint 6 NFTs, you will have to get in line 3
          times.
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          How many pieces can I mint when my turn comes?
        </Text>
        <Text fontSize="md">
          All of those that you lined up for. If you are in positions 1,10,15,
          as soon as position 1 comes, you can mint 6 NFTs.
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          How long do I have to mint when my turn comes?
        </Text>{" "}
        <Text fontSize="md">
          You can mint at any time from your turn until the end of The Queue.
        </Text>{" "}
        <Text fontSize="lg" fontWeight="bold">
          What is the supply?
        </Text>{" "}
        <Text fontSize="md">
          The supply will be determined by the total number of blocks in the
          line.
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          When can I start trading my tokens?
        </Text>{" "}
        <Text fontSize="md">
          Holders will be able to trade their tokens right after the queue is
          over or after a sold out, if earlier. This is enforced by the
          protocol.
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          Is the project 100% on-chain?
        </Text>{" "}
        <Text fontSize="md">
          Yes. The queue is a protocol (so yes, on-chain), and the art is
          rendered fully from the smart contract, including the big photo of the
          queue that you will see (or saw) in the middle of the screen.
        </Text>
      </Box>
    </Box>
  );
}
