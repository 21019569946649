import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "./InFrontText.css";

type InFrontTextProps = {
  firstPosition: bigint | undefined;
  mintBegin: bigint | undefined;
  canMint: boolean;
  currentBlock: bigint | undefined;
  count: number;
  update: boolean;
};

export default function InFrontText(props: InFrontTextProps) {
  const [inFront, setInFront] = useState<bigint>();
  const [mintOpen, setMintOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.currentBlock !== undefined &&
      props.mintBegin !== undefined &&
      props.firstPosition !== undefined
    ) {
      if (props.canMint) {
        setInFront(BigInt(0));
        return;
      }

      if (props.mintBegin > props.currentBlock) {
        setInFront(props.firstPosition);
        return;
      }

      setInFront(props.firstPosition + props.mintBegin - props.currentBlock);
    } else {
      setInFront(undefined);
    }
  }, [props]);

  useEffect(() => {
    if (
      props.currentBlock !== undefined &&
      props.mintBegin !== undefined &&
      props.currentBlock >= props.mintBegin
    ) {
      setMintOpen(true);
    } else {
      setMintOpen(false);
    }
  }, [props.mintBegin, props.currentBlock]);

  return (
    <Box>
      <Text style={{ textAlign: "center" }} variant="home">
        {inFront !== undefined && inFront === BigInt(0) && mintOpen && (
          <Box>
            <span className="blink">YOUR TURN</span>
          </Box>
        )}
        {inFront !== undefined && (!mintOpen || inFront > BigInt(0)) && (
          <Box className={`${props.update ? "highlight" : ""}`}>
            <span>{`${inFront} in front`}</span>
          </Box>
        )}
        {inFront === undefined && <span>You're not in line.</span>}
      </Text>
    </Box>
  );
}
