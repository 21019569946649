import { Box, Text } from "@chakra-ui/react";
import { formatEther } from "viem";

type PriceTextProps = {
  price: bigint | undefined;
  discountPrice: bigint | undefined;
  hasDiscount: boolean | undefined;
};

export default function PriceText(props: PriceTextProps) {
  return (
    <Box marginLeft={1}>
      {props.price !== undefined &&
        props.hasDiscount &&
        props.discountPrice !== undefined && (
          <Text>
            <s>{parseFloat(formatEther(props.price)).toFixed(4)}Ξ</s>
            <br />
            <Text color="red">
              {parseFloat(formatEther(props.discountPrice)).toFixed(4)}Ξ
            </Text>
          </Text>
        )}
      {props.price !== undefined && !props.hasDiscount && (
        <Text>{parseFloat(formatEther(props.price)).toFixed(4)}Ξ</Text>
      )}
    </Box>
  );
}
