import { useBlockNumber } from "wagmi";
import { useEffect, useState } from "react";

export default function useBlock() {
  let [block, setBlock] = useState<bigint>();

  const { data, refetch } = useBlockNumber({ watch: false });

  useEffect(() => {
    if (data) {
      const result = data as bigint;
      setBlock(result);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(refetch, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  return block;
}
