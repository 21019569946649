import { Box, Text } from "@chakra-ui/react";
import { useCountdown } from "./useCountdown";
type CountdownTextProps = {
  mintBegin: bigint | undefined;
  currentBlock: bigint | undefined;
};

export default function CountdownText(props: CountdownTextProps) {
  const secondsLeft =
    (Number(props.mintBegin) - Number(props.currentBlock)) * 12;
  const [hour, minutesDisplay, secondsDisplay] = useCountdown(secondsLeft);

  return (
    <Box>
      {secondsLeft > 0 && (
        <Text marginTop={1} variant="home">
          {hour}h {minutesDisplay}m {secondsDisplay}s
        </Text>
      )}
    </Box>
  );
}
