import { Box } from "@chakra-ui/react";
import useLine from "./useLine";

type LineProps = {
  line: string;
};

export default function Line(props: LineProps) {
  // 4. Use modal hook

  return (
    <Box maxW="100%" w="100%" h="100%">
      <div
        dangerouslySetInnerHTML={{ __html: atob(props.line.slice(26)) }}
        style={{
          padding: "0px",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />
    </Box>
  );
}
