import { useEffect, useState } from "react";

type Entry = {
  proof: string[];
  freeAmount: number;
};

export type DB = Record<string, Entry>;

export default function useDiscount(address: `0x${string}` | undefined) {
  const [db, setDb] = useState<DB>();
  const [freeAmount, setFreAmount] = useState<number>(0);
  const [hasDiscount, setHasDiscount] = useState<boolean>(false);
  const [proof, setProof] = useState<string[]>();

  useEffect(() => {
    fetch("db.json")
      .then((response) => response.json())
      .then((data) => {
        setDb(data);
      });
  }, []);

  useEffect(() => {
    if (address && db && db[address] !== undefined) {
      setFreAmount(db[address].freeAmount);
      setHasDiscount(true);
      setProof(db[address].proof);
    } else {
      setFreAmount(0);
      setHasDiscount(false);
      setProof(undefined);
    }
  }, [address, db]);

  return {
    hasDiscount: hasDiscount,
    freeAmount: freeAmount,
    proof: proof,
  };
}
