import { Box, Flex, HStack, Spacer, Text } from "@chakra-ui/react";
import Line from "./Line";
import "./Main.css";
import CountdownText from "./Countdown";
import "./ContentMobile.css";
import ConnectButtonMobile from "./ConnectButtonMobile";
import InFrontText from "./InFrontText";
import MintButtonMobile from "./MintButtonMobile";
import EnterButtonMobile from "./EnterButtonMobile";

function ContentMobile(props: {
  size: number;
  auctionRef: React.RefObject<HTMLHeadingElement>;
  line: string;
  setUpdate: (doUpdate: boolean) => void;
  update: boolean;
  firstPosition: bigint | undefined;
  positions: bigint[];
  mintBegin: bigint;
  queueBegin: bigint;
  canMint: boolean;
  currentBlock: bigint | undefined;
  walletSvg: string | undefined;
  ensOrAddress: string | undefined;
  count: number;
  lineLength: bigint | undefined;
  currentTokenId: bigint | undefined;
}) {
  const options = {
    month: "long" as const,
    day: "numeric" as const,
    hour: "2-digit" as const,
    minute: "2-digit" as const,
    hour12: false, // Use 24-hour format, change to true for AM/PM format
  };

  const mintingDate = new Date(process.env.REACT_APP_MINTING_DATE!);
  const showBoxes =
    props.currentBlock && props.queueBegin >= props.currentBlock + BigInt(4);

  return (
    <Flex width="100%" marginTop={5} align="center" direction="column">
      {/* Left box taking up remaining space */}
      <Flex direction="row" width="100%">
        <Spacer />
        {props.currentBlock !== undefined &&
          props.mintBegin !== undefined &&
          props.currentBlock >= props.mintBegin && (
            <Box
              className="box box-mobile"
              width={props.size / 4.2}
              height={props.size / 4.2}
            >
              <MintButtonMobile
                mintBegin={props.mintBegin}
                currentBlock={props.currentBlock}
                positions={props.positions}
                canMint={props.canMint}
                lineLength={props.lineLength}
                count={props.count}
              />

              <CountdownText
                mintBegin={props.mintBegin}
                currentBlock={props.currentBlock}
              />
            </Box>
          )}
        {props.currentBlock !== undefined &&
          props.mintBegin !== undefined &&
          props.currentBlock < props.mintBegin && (
            <Box
              className="box box-mobile"
              width={props.size / 4.2}
              height={props.size / 4.2}
            >
              <EnterButtonMobile
                mintBegin={props.mintBegin}
                currentBlock={props.currentBlock}
                update={() => props.setUpdate(true)}
                count={props.count}
                queueBegin={props.queueBegin}
              />

              <CountdownText
                mintBegin={props.queueBegin}
                currentBlock={props.currentBlock}
              />
            </Box>
          )}
        <Spacer />
        <Box
          className="box box-mobile"
          width={props.size / 4.2}
          height={props.size / 4.2}
        >
          <Text fontSize="1.4em" fontWeight="bold">
            The Queue
          </Text>
        </Box>
        <Spacer />
        <Box
          className="box box-mobile"
          width={props.size / 4.2}
          height={props.size / 4.2}
        >
          <ConnectButtonMobile
            walletSvg={props.walletSvg}
            ensOrAddress={props.ensOrAddress}
          />
        </Box>
        <Spacer />
      </Flex>

      {/* Central box with fixed props.size */}
      <Box position="relative" width={props.size} height={props.size}>
        <Line line={props.line} />
        <Box
          width="90px"
          height="90px"
          top="20%"
          left="15%"
          fontSize="1em"
          className={`home-box-mobile show-${showBoxes}-mobile`}
        >
          Queue.
        </Box>
        <Box
          width="90px"
          height="90px"
          fontSize="1em"
          top="20%"
          left="40%"
          className={`home-box-mobile show-${showBoxes}-mobile`}
        >
          Wait.
        </Box>
        <Box
          width="90px"
          height="90px"
          fontSize="1em"
          top="20%"
          left="65%"
          className={`home-box-mobile show-${showBoxes}-mobile`}
        >
          Mint.
        </Box>
        <Box
          width="100px"
          height="100px"
          top="50%"
          left="25%"
          className={`home-box-mobile colored-red show-${showBoxes}-mobile`}
        >
          The
        </Box>
        <Box
          width="100px"
          height="100px"
          top="50%"
          left="52%"
          className={`home-box-mobile colored-green show-${showBoxes}-mobile`}
        >
          Queue.
        </Box>
      </Box>
      <HStack>
        {props.currentBlock !== undefined &&
          props.mintBegin !== undefined &&
          props.currentBlock < props.mintBegin && (
            <Box
              width={props.size / 4.2}
              height={props.size / 4.2}
              className="box box-mobile"
              fontSize="13px"
              marginTop={5}
              marginBottom={10}
            >
              Minting begins on
              <br /> {mintingDate.toLocaleString("en-US", options)}
            </Box>
          )}
        {props.currentBlock !== undefined && props.mintBegin !== undefined && (
          <Box
            width={props.size / 4.2}
            height={props.size / 4.2}
            className="box box-mobile"
            marginTop={5}
            marginBottom={10}
          >
            <InFrontText
              firstPosition={props.firstPosition}
              canMint={props.canMint}
              mintBegin={props.mintBegin}
              currentBlock={props.currentBlock}
              count={props.count}
              update={props.update}
            />
          </Box>
        )}
      </HStack>
    </Flex>
  );
}

export default ContentMobile;
