import { useAccount, useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";

export default function useAccountData(props: {
  update: boolean;
  unsetUpdate: () => void;
  mintBegin: bigint | undefined;
  currentBlock: bigint | undefined;
}) {
  let [ensOrAddress, setEnsOrAddress] = useState<string>();
  let [walletSvg, setWalletSvg] = useState<string>();
  let [positions, setPositions] = useState<bigint[]>([]);
  let [firstPosition, setFirstPosition] = useState<bigint>();
  let [count, setCount] = useState<number>(0);
  let [canMint, setCanMint] = useState<boolean>(false);
  const { isConnected, address } = useAccount();

  const { data, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "accountData",
    watch: false,
    args: [
      isConnected ? address : "0x0000000000000000000000000000000000000000",
    ],
  });

  useEffect(() => {
    if (props.mintBegin && props.currentBlock && firstPosition !== undefined) {
      setCanMint(
        props.currentBlock + BigInt(1) >= props.mintBegin + firstPosition
      );
    } else {
      setCanMint(false);
    }
  }, [props, firstPosition]);

  useEffect(() => {
    if (count > 0) {
      setFirstPosition(positions[0]);
    }
  }, [count, positions]);

  useEffect(() => {
    setFirstPosition(undefined);
    setCanMint(false);
    setPositions([]);
    setWalletSvg(undefined);
    setEnsOrAddress(address);
    setCount(0);
    refetch();
  }, [address]);

  useEffect(() => {
    console.log("update");
    if (props.update) {
      console.log("updat2");
      refetch();
      props.unsetUpdate();
    }
  }, [props.update]);

  useEffect(() => {
    if (data) {
      const [ensOrAddressData, walletSvgData, positionsData] = data as [
        string,
        string,
        bigint[]
      ];
      setWalletSvg(walletSvgData);
      setEnsOrAddress(ensOrAddressData);
      if (positionsData.length > 0) {
        setPositions(positionsData.slice(0, positionsData.length));
        setCount(positionsData.length);
      }
    }
  }, [data]);

  return {
    ensOrAddress: ensOrAddress,
    walletSvg: walletSvg,
    firstPosition: firstPosition,
    positions: positions,
    count: count,
    canMint: canMint,
  };
}
